import cx from 'classnames';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, {
  Suspense,
  FunctionComponent,
  memo,
  useEffect,
  ComponentProps,
  useState,
  useCallback,
} from 'react';
import { LiveChatLoaderProvider, useChat } from 'react-live-chat-loader';

import { originalRoutes } from '../../../hooks/use-link-props';
import useLoggedUser from '../../../hooks/use-logged-user';
import { useAppContext } from '../../providers/app-context';
import Button from '../../ui/button';
import BPImage from '../../ui/image';

const { publicRuntimeConfig } = getConfig();
const APP_ID = publicRuntimeConfig.intercomKey;

const OPEN_EVENT = 'open-intercom';

export const fireEventOpen = () => {
  const event = new Event(OPEN_EVENT);

  document.dispatchEvent(event);
};

const IntercomButton: FunctionComponent<{ isPlaceholder?: boolean }> = ({
  isPlaceholder = false,
  ...rest
}) => {
  const [_, loadChat] = useChat({ loadWhenIdle: true });

  useEffect(() => {
    const fn = () => loadChat({ open: true });
    document.addEventListener('open-intercom', fn);

    return () => {
      document.removeEventListener('open-intercom', fn);
    };
  }, []);

  return (
    <Button
      {...rest}
      onClick={() => loadChat({ open: true })}
      onMouseEnter={() => loadChat({ open: false })}
      isFullWidth={false}
      isSmall={true}
      className={cx(
        'h-12',
        'w-12',
        'text-center',
        'lg:block',
        'lg:col-span-4',
        'lg:justify-self-end'
      )}
      disableFocusOnMenu
    >
      <div
        className={cx(
          'rounded-full',
          'h-8',
          'w-8',
          'flex',
          'items-center',
          'justify-center',
          isPlaceholder ? 'bg-neutral-30' : 'bg-brand-100'
        )}
      >
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/intercom.svg`}
          height={16}
          width={14}
          alt="Live chat"
        />
      </div>
    </Button>
  );
};

export const IntercomPlaceHolder = () => <IntercomButton isPlaceholder />;

export const IntercomLoader = ({ placeholder, children }) => {
  const user = useLoggedUser();

  const updateUserInfo = useCallback(() => {
    if (user && window && window.Intercom) {
      fetch('/api/intercom-hash?userId=' + user.staff.id)
        .then((res) => res.json())
        .then((data) => {
          const { hash } = data;

          window.Intercom('update', {
            user_id: user.staff.id,
            name: user.staff.name,
            email: user.staff.email,
            user_hash: hash,
          });
        })
        .catch((err) => {
          console.error('Erro ao obter hash', err);
        });
    }
  }, [user]);

  useEffect(() => {
    updateUserInfo();
  }, [user, updateUserInfo]);

  return (
    <Suspense fallback={placeholder}>
      <LiveChatLoaderProvider
        providerKey={APP_ID}
        provider="intercom"
        idlePeriod={2000}
        beforeInit={() =>
          setTimeout(() => {
            window.Intercom('update', {
              hide_default_launcher: true,
            });
            updateUserInfo();
          }, 0)
        }
      >
        {children}
      </LiveChatLoaderProvider>
    </Suspense>
  );
};

const Intercom: FunctionComponent = () => {
  const { pathname } = useRouter();

  useEffect(() => {
    if (pathname !== originalRoutes.product) {
      if (window.intercomSettings) {
        delete window.intercomSettings.last_part_type;
        delete window.intercomSettings.last_product_status;
        delete window.intercomSettings.last_page_value;
      }
    }
  }, [pathname]);

  return <IntercomButton />;
};

const IntercomWrapper: FunctionComponent = () => {
  const {
    concent: { required },
  } = useAppContext();

  if (!required) {
    return <IntercomPlaceHolder />;
  }
  return (
    <IntercomLoader placeholder={<IntercomPlaceHolder />}>
      <Intercom />
    </IntercomLoader>
  );
};

export default memo(IntercomWrapper);

export const IntercomButtonGeneric = ({
  initiateImmediately = false,
  ...buttonProps
}: { initiateImmediately?: boolean } & ComponentProps<typeof Button>) => {
  const [_, loadChat] = useChat({ loadWhenIdle: true });

  useEffect(() => {
    if (initiateImmediately) loadChat({ open: false });
  }, []);

  return (
    <Button {...buttonProps} onMouseEnter={() => loadChat({ open: false })} />
  );
};

export const IntercomWrapperGeneric = (
  buttonProps: { initiateImmediately?: boolean } & ComponentProps<typeof Button>
) => {
  const {
    concent: { required },
  } = useAppContext();

  if (!required) {
    return <IntercomButtonGeneric {...buttonProps} />;
  }
  return (
    <IntercomLoader placeholder={<IntercomPlaceHolder />}>
      <IntercomButtonGeneric {...buttonProps} />
    </IntercomLoader>
  );
};
